import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  EditButton,
  EmailField,
  ReferenceManyField
} from 'react-admin'

import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { postJSON } from '../../https'
import { AxiosError } from 'axios'
import { AttachMoney } from '@material-ui/icons'

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
} as any

export const WorkerprofileShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="auth0Id" />

      <TextField source="name" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="fbUserId" />

      <TextField source="birthDay" />
      <TextField source="phoneNumber" />
      <EmailField source="email" />
      <TextField source="status" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="birthName" />
      <TextField source="mothersName" />
      <TextField source="socialSecurityNumber" />
      <TextField source="taxNumber" />
      <TextField source="studentIdNumber" />
      <ReferenceManyField
        label="Transfers"
        perPage={10}
        reference="transfer"
        target="workerProfile.id"
      >
        <Datagrid>
          <TextField source="description" />
          <NumberField source="amount" />
          <DateField source="createdAt" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)
