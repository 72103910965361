import React, { Component } from 'react'
import { Button } from 'react-admin'
import { postJSON } from '../../https';


function download(filename: string, mimeType: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,${encodeURIComponent(text)}`);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

const GeneratePAINButton = (props: any) => {
    return <Button label="Export PAIN" onClick={async () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = props
        const { painXml } = await postJSON(`/api/admin/payout`, { invoiceIds: selectedIds })
        download(`payout-${new Date().toISOString()}.xml`, 'application/xml', painXml)
    }} />;
}

export { GeneratePAINButton }