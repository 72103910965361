import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin'
import * as _ from 'lodash'
import axios from 'axios'
import { getJSON } from './https'

const admins = {
  Tom: '+36709445242',
  Pella: '+36709411270',
  Peter: '+36209195707',
  Kalko: '+36309890069',
  Mezo: '+36308336255',
  Rolcsa: '+36207777051',
}

export default async (type: any, params: any) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params

    localStorage.setItem('username', username)
    const numbers = _.values(admins)
    if (!numbers.includes(username)) {
      throw new Error('Not an admin phone number. Use +36 as a prefix.')
    }
    if (password === 'code') {
      await axios.post('/api/admin/auth/sendSMS', {
        phoneNumber: username
      })
      throw new Error('Enter code received in sms.')
    } else {
      const resp = await axios.post('/api/admin/auth/verify', {
        phoneNumber: username,
        code: password
      })
      console.log(resp.data)
      localStorage.setItem('access_token', resp.data.access_token)
      return true
    }
    // accept all username/password combinations
    // return Promise.resolve()
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('username')
    return Promise.resolve()
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      return Promise.reject()
    }
    return Promise.resolve()
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    const resp = await getJSON('/api/admin/auth/me')
    console.log(resp)
    return true
    // return localStorage.getItem('username')
    //   ? Promise.resolve()
    //   : Promise.reject()
  }
  return Promise.reject('Unknown method')
}
