import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  EditButton,
  EmailField,
  ReferenceManyField,
  BooleanField
} from 'react-admin'

import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { postJSON } from '../../https'
import { AxiosError } from 'axios'
import { AttachMoney } from '@material-ui/icons'

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
} as any

export const CompanyShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="rake" />
      <TextField source="postCode" />
      <TextField source="city" />
      <TextField source="addressLine1" />
      <TextField source="country" />
      <EmailField source="email" />
      <DateField source="vatNumber" />
      <DateField source="number" />
      <BooleanField source="active" />
      <ReferenceManyField
        label="Transfers"
        perPage={10}
        reference="transfer"
        target="company.id"
      >
        <Datagrid>
          <TextField source="description" />
          <NumberField source="amount" />
          <DateField source="createdAt" />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
)
