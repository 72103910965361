import React from 'react'
import { Datagrid, DateField, List, NumberField, TextField } from 'react-admin'

export const TransferList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="description" />
      <NumberField source="amount" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)
