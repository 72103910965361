import React from 'react'
import {
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'

export const TransferCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="description" />
      <NumberInput source="amount" />
      <ReferenceInput label="Account of" source="accountId" reference="account">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)
