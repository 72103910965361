import React from 'react'
import {
  DateField,
  DateInput,
  Edit,
  NumberField,
  NumberInput,
  ReferenceField,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar
} from 'react-admin'

//Lets define so custom fields so confirmations wont be editable after they closed
const MyNumberField = (props: { record?: any; source: any }) => {
  console.log(25, props)
  const Comp = props.record.wages ? NumberField : NumberInput
  return <Comp {...props} />
}

const MyDateField = (props: { record?: any; source: any }) => {
  console.log(25, props)
  const Comp = props.record.wages ? DateField : DateInput
  return <Comp {...props} />
}

const MyToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      label="post.action.save_and_show"
      redirect="show"
      submitOnEnter={true}
      undoable={false}
    />
    <SaveButton
      label="post.action.save"
      submitOnEnter={false}
      undoable={false}
      variant="flat"
    />
  </Toolbar>
)

export const JobconfirmationEdit = (props: any) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<MyToolbar />}>
        <TextField source="id" />

        <MyNumberField source="hoursWorked" />
        <MyDateField source="workerConfirmedAt" />
        <MyDateField source="managerConfirmedAt" />

        <NumberField source="invoicedHourlyWage" />
        <NumberField source="year" options={{ useGrouping: false }} />
        <NumberField source="month" />
        <TextField source="wages.total.invoiced" />

        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <ReferenceField source="job.id" reference="job">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="worker.name" />
        <ReferenceField source="company.id" reference="company">
          <TextField source="name" />
        </ReferenceField>
      </SimpleForm>
    </Edit>
  )
}
