import React, { Fragment } from 'react'
import {
    List,
    Datagrid,
    TextField,
    DateField,
    BooleanField,
} from 'react-admin'
import { CardActions } from '@material-ui/core';
import { UploadPAINButton } from './UploadPAINButton';

const PayoutActions = (props: any) => {
    return <CardActions>
        <UploadPAINButton />
    </CardActions>
}

export const PayoutList = (props: any) => (
    <List {...props} actions={<PayoutActions />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <BooleanField source="sent" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List >
);