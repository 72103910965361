import React from 'react'
import {
  Admin,
  Resource,
  ListGuesser,
  ShowGuesser,
  EditGuesser,
  fetchUtils,
  CreateGuesser
} from 'react-admin'
// import jsonServerProvider from 'ra-data-json-server'
import simpleRestProvider from 'ra-data-simple-rest'
import authProvider from './authProvider'
import {
  JobconfirmationList,
  JobconfirmationEdit,
  JobconfirmationShow
} from './resources/jobConfirmation/index'
import { AccountList } from './resources/account'
import { WorkerprofileList, WorkerprofileShow } from './resources/workerProfile'
import { CompanyList } from './resources/company/list'
import { CompanyShow } from './resources/company/show'
import { ManagerInviteCreate } from './resources/managerInvite/create'
import { TransferList, TransferCreate } from './resources/transfer'
import { ManagerprofileEdit } from './resources/managerProfile'
import { InvoiceList } from './resources/invoice/list'
import { PayoutList } from './resources/payout/list'
import { ManagerinviteList } from './resources/managerInvite'

const httpClient = (url: string, options = {} as any) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  // add your own headers here
  options.headers.set(
    'Authorization',
    `Bearer ${window.localStorage.getItem('access_token')}`
  )
  return fetchUtils.fetchJson(url, options)
}

const dataProvider = simpleRestProvider('/api/admin', httpClient)
const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource
      name="account"
      list={AccountList}
      edit={EditGuesser}
      show={ShowGuesser}
    />
    <Resource
      name="company"
      list={CompanyList}
      edit={EditGuesser}
      show={CompanyShow}
    />
    <Resource
      name="jobConfirmation"
      list={JobconfirmationList}
      edit={JobconfirmationEdit}
      show={JobconfirmationShow}
    />
    <Resource
      name="managerInvite"
      list={ManagerinviteList}
      create={ManagerInviteCreate}
      show={ShowGuesser}
    />
    <Resource
      name="managerProfile"
      list={ListGuesser}
      edit={ManagerprofileEdit}
      show={ShowGuesser}
    />
    <Resource
      name="transfer"
      list={TransferList}
      edit={EditGuesser}
      show={ShowGuesser}
      create={TransferCreate}
    />
    <Resource
      name="workerProfile"
      list={WorkerprofileList}
      edit={EditGuesser}
      show={WorkerprofileShow}
    />
    <Resource
      name="payout"
      list={PayoutList}
      edit={EditGuesser}
      show={ShowGuesser}
    />
    <Resource
      name="invoice"
      list={InvoiceList}
      // edit={EditGuesser}
      show={ShowGuesser}
    />
    <Resource
      name="managerInvite"
      list={ListGuesser}
      edit={EditGuesser}
      show={ShowGuesser}
    />
  </Admin>
)

export default App
