import React, { Component } from 'react'
import { Button } from 'react-admin'
import { postJSON, postFileData } from '../../https';




const UploadPAINButton = (props: any) => {
    return <Button label="Upload PAIN" onClick={async () => {
        const element: HTMLInputElement = document.createElement('input');
        element.setAttribute('type', 'file');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.onchange = function (ev) {
            let file = element.files!![0];
            let formData = new FormData();

            formData.append("file", file);
            postFileData('/api/admin/payout/uploadPAIN', formData);
        }

        element.click();
    }} />;
}

export { UploadPAINButton }