import React from 'react'
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EmailField,
  List,
  ReferenceManyField,
  SingleFieldList,
  TextField
} from 'react-admin'

export const CompanyList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceManyField
        label="Managers"
        reference="managerProfile"
        target="companyId"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="rake" />
      <TextField source="postCode" />
      <TextField source="city" />
      <TextField source="addressLine1" />
      <TextField source="country" />
      <EmailField source="email" />
      <DateField source="vatNumber" />
      <DateField source="number" />
      <BooleanField source="active" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)
