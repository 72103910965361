import React from 'react'
import { Datagrid, DateField, EmailField, List, TextField } from 'react-admin'
export const ManagerinviteList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="phoneNumber" />
      <TextField source="status" />
      <TextField source="acceptedAt" />
      <DateField source="createdAt" />
      <TextField source="company" />
    </Datagrid>
  </List>
)
