import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  EditButton
} from 'react-admin'

import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { postJSON } from '../../https'
import { AxiosError } from 'axios'
import { AttachMoney } from '@material-ui/icons'

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
} as any

async function closeConfirmation(data: any, resource: any) {
  try {
    await postJSON(`/api/admin/jobConfirmation/close/${data.id}`, {})
    window.location.reload()
  } catch (e) {
    console.log(e.response)
    alert('Error!')
  }

  console.log({ data, resource })
}

const Actions = ({ basePath, data, resource }: any) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    {/* Add your custom actions */}
    {data && !data.wages && (
      <Button color="primary" onClick={() => closeConfirmation(data, resource)}>
        Close Confirmation{' '}
      </Button>
    )}
  </CardActions>
)

export const JobconfirmationShow = (props: any) => (
  <Show {...props} actions={<Actions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="hoursWorked" />
      <NumberField source="invoicedHourlyWage" />
      <NumberField source="year" />
      <NumberField source="month" />
      <TextField source="wages" />
      <DateField source="workerConfirmedAt" />
      <DateField source="managerConfirmedAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="job.id" />
      <TextField source="worker.id" />
      <TextField source="manager" />
      <TextField source="company.id" />
    </SimpleShowLayout>
  </Show>
)
