import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  BooleanInput
} from 'react-admin'

const MyFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Company"
      source="companyId"
      reference="company"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      source="year"
      choices={[
        { id: '2019', name: '2019' },
        { id: '2020', name: '2020' },
        { id: '2021', name: '2021' }
      ]}
      alwaysOn
    />
    <SelectInput
      source="month"
      choices={[
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
        { id: '5', name: '5' },
        { id: '6', name: '6' },
        { id: '7', name: '7' },
        { id: '8', name: '8' },
        { id: '9', name: '9' },
        { id: '10', name: '10' },
        { id: '11', name: '11' },
        { id: '12', name: '12' }
      ]}
      alwaysOn
    />
    <BooleanInput
      label="Filter unconfirmed"
      source="unconfirmedOnly"
      alwaysOn
    />
  </Filter>
)

export const JobconfirmationList = (props: any) => (
  <List {...props} filters={<MyFilter />}>
    <Datagrid rowClick="show">
      <TextField source="worker.name" />
      <ReferenceField source="company.id" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="hoursWorked" />
      <NumberField source="invoicedHourlyWage" />
      <NumberField source="year" options={{ useGrouping: false }} />
      <NumberField source="month" />
      <NumberField source="wages.total.invoiced" />
      <NumberField source="wages.total.invoiced" />
      <DateField source="workerConfirmedAt" />
      <DateField source="managerConfirmedAt" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
)
