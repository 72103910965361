import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  BooleanInput
} from 'react-admin'

export const AccountList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <NumberField source="balance" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <ReferenceField source="worker.id" reference="workerProfile">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)
