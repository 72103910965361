import axios from 'axios'
// import { debounce } from 'lodash'

export function getJSON<T>(
  url: string,
  headers: { [key: string]: any } = {},
  toastOnFail = true
): Promise<T> {
  const finalHeaders = {
    ...headers,
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
  }
  return axios
    .get<T>(url, {
      headers: finalHeaders,
      responseType: 'json'
    })
    .then(a => a.data)
    .catch(err => {
      // if (toastOnFail) warnRequestFailedDebounced()
      throw new Error(err)
    }) as any
}

export function postJSON<T>(
  url: string,
  data: { [key: string]: any },
  toastOnFail = true
): Promise<T> {
  const headers = {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
  }
  return axios
    .post<T>(url, data, {
      headers,
      responseType: 'json'
    })
    .then(a => a.data)
    .catch(err => {
      // if (toastOnFail) warnRequestFailedDebounced()
      throw new Error(err)
    }) as any
}

export function postFileData<T>(
  url: string,
  data: FormData,
): Promise<T> {
  const headers = {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`
  }
  return axios
    .post<T>(url, data, {
      headers,
      responseType: 'json'
    })
    .then(a => a.data)
}
