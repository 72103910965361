import React, { Fragment } from 'react'
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    DateField,
    BulkDeleteButton,
    BooleanField,
} from 'react-admin'
import { GeneratePAINButton } from './GeneratePAINButton';

const PostBulkActionButtons = (props: any) => (
    <Fragment>
        <GeneratePAINButton  {...props} />
        {/* Add the default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);


export const InvoiceList = (props: any) => (
    <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>
            <TextField source="id" />
            <NumberField source="amount" />
            <BooleanField source="payoutConfirmed" />
            <TextField source="year" />
            <TextField source="month" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);