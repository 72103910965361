import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  EmailField,
  DateField,
  ReferenceField,
  DateTimeField,
  DateTimeInput,
  TextInput,
  Filter,
  Edit,
  SimpleForm,
  NumberInput,
  DateInput,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  RichTextField,
  BooleanInput
} from 'react-admin'

export const WorkerprofileList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="firstName" />
      <TextField source="lastName" />

      <TextField source="birthDay" />
      <TextField source="phoneNumber" />
      <EmailField source="email" />
      <TextField source="status" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <TextField source="birthName" />
      <TextField source="mothersName" />
      <TextField source="socialSecurityNumber" />
      <TextField source="taxNumber" />
      <TextField source="studentIdNumber" />
    </Datagrid>
  </List>
)
