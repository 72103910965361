import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  DateInput,
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'

export const ManagerprofileEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <ReferenceInput source="auth0Id" reference="auth0s">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="email" />
      <TextInput source="phoneNumber" />
      <DateInput source="createdAt" />
      <DateInput source="updatedAt" />
      <ArrayInput source="companies">
        <SimpleFormIterator>
          <TextInput source="id" />
          <TextInput source="name" />
          <TextInput source="rake" />
          <DateInput source="postCode" />
          <TextInput source="city" />
          <TextInput source="addressLine1" />
          <TextInput source="country" />
          <TextInput source="email" />
          <DateInput source="vatNumber" />
          <DateInput source="number" />
          <BooleanInput source="active" />
          <DateInput source="createdAt" />
          <DateInput source="updatedAt" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)
